/* eslint-disable react-hooks/exhaustive-deps */
'use client'

import { ReactNode, createContext, useEffect, useState } from 'react'
import { isBrowser } from '@/utils/env'

export type QueryParams = Record<string, string | number | boolean>

type TrackingContextProps = {
  referrer?: string
  tracking: Record<string, string | number | boolean>
  queryParams?: QueryParams
}

type TrackingProviderProps = {
  children: ReactNode
  tracking?: Record<string, string | number | boolean>
}

const TrackingContext = createContext<TrackingContextProps>({
  referrer: undefined,
  tracking: {},
  queryParams: undefined
})

const TrackingProvider = ({
  children,
  tracking = {}
}: TrackingProviderProps) => {
  const [referrer, setReferrer] = useState<string | undefined>()
  const [queryParams, setQueryParams] = useState<QueryParams | undefined>()

  useEffect(() => {
    if (!isBrowser) return

    if (!referrer && document.referrer) {
      const url = new URL(document.referrer)
      const referrerURL = url.hostname.replace('www.', '')
      !!referrerURL && setReferrer(referrerURL)
    }

    if (!queryParams) {
      const params: QueryParams = {}
      const searchParams = new URLSearchParams(window.location.search)

      searchParams.forEach((value, key) => {
        params[key] = value
      })

      setQueryParams(params)
    }
  }, [])

  return (
    <TrackingContext.Provider value={{ tracking, referrer, queryParams }}>
      {children}
    </TrackingContext.Provider>
  )
}

export { TrackingContext, TrackingProvider }
