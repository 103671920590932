import { ThemeConfig } from 'tailwindcss/types/config'

const backgroundImage = {
  none: 'none',
  'gradient-to-t': 'linear-gradient(to top, var(--tw-gradient-stops))',
  'gradient-to-tr': 'linear-gradient(to top right, var(--tw-gradient-stops))',
  'gradient-to-r': 'linear-gradient(to right, var(--tw-gradient-stops))',
  'gradient-to-br':
    'linear-gradient(to bottom right, var(--tw-gradient-stops))',
  'gradient-to-b': 'linear-gradient(to bottom, var(--tw-gradient-stops))',
  'gradient-to-bl': 'linear-gradient(to bottom left, var(--tw-gradient-stops))',
  'gradient-to-l': 'linear-gradient(to left, var(--tw-gradient-stops))',
  'gradient-to-tl': 'linear-gradient(to top left, var(--tw-gradient-stops))',
  header: 'linear-gradient(180deg, #666666 -270%, rgba(0, 0, 0, 0.6) 150%)',
  'select-arrow':
    'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC4yOTI4OTMgMC4yOTI4OTNDMC42ODM0MTYgLTAuMDk3NjMxIDEuMzE2NTggLTAuMDk3NjMxIDEuNzA3MSAwLjI5Mjg5M0w0Ljk5OTk5IDMuNTg1NzlMOC4yOTI4OCAwLjI5Mjg5M0M4LjY4MzQgLTAuMDk3NjMxMSA5LjMxNjU3IC0wLjA5NzYzMTEgOS43MDcwOSAwLjI5Mjg5M0MxMC4wOTc2IDAuNjgzNDE3IDEwLjA5NzYgMS4zMTY1OCA5LjcwNzA5IDEuNzA3MTFMNS43MDcxIDUuNzA3MTFDNS4zMTY1NyA2LjA5NzYzIDQuNjgzNDEgNi4wOTc2MyA0LjI5Mjg5IDUuNzA3MTFMMC4yOTI4OTMgMS43MDcxMUMtMC4wOTc2MzA5IDEuMzE2NTggLTAuMDk3NjMwOSAwLjY4MzQxNyAwLjI5Mjg5MyAwLjI5Mjg5M1oiIGZpbGw9IiNCNUI1QjUiLz4KPC9zdmc+Cg==)'
} satisfies ThemeConfig['backgroundImage']

export { backgroundImage }
