export const baseUrl =
  process?.env?.NEXT_PUBLIC_BASE_URL || 'http://localhost:3000'

export const strapiUrl = process?.env?.NEXT_PUBLIC_STRAPI_API_URL || ''

export const siteName = process?.env?.SITE_NAME || ''

export const isProd = process?.env?.NODE_ENV === 'production'

export const isDev = process?.env?.NODE_ENV === 'development'

export const isPreview = ['true', true].includes(
  process?.env?.NEXT_PUBLIC_PREVIEW_ENV as any
)

export const isBrowser = typeof window !== 'undefined'

export const gaTrackingId = process?.env?.NEXT_PUBLIC_GA_TRACKING_ID || ''

export const gtmId = process?.env?.NEXT_PUBLIC_GTM_ID || ''
