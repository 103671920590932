import { gaTrackingId, isBrowser, isPreview } from './env'

export type GoogleEventProps = {
  eventName: string
  eventParams: Record<string, unknown>
}

export const dispatchGoogleEvent = (
  eventName: GoogleEventProps['eventName'],
  eventParams: GoogleEventProps['eventParams'] | string
) => {
  if (isPreview || !isBrowser || !gaTrackingId) return

  const params =
    typeof eventParams === 'string' ? { label: eventParams } : eventParams

  if (typeof gtag !== 'undefined') {
    gtag('event', eventName, params)
  }

  if (typeof fbq !== 'undefined') {
    fbq('trackCustom', eventName, params)
  }
}

export const pageView = (pathname: string) => {
  if (typeof gtag !== 'undefined') {
    // @ts-ignore
    gtag('send', 'pageview', pathname)
  }
}
