import { ThemeConfig } from 'tailwindcss/types/config'

const fontSize = {
  'display-10': '0.625rem',
  'display-12': '0.75rem',
  'display-14': '0.875rem',
  'display-16': '1rem',
  'display-18': '1.125rem',
  'display-20': '1.25rem',
  'display-24': '1.5rem',
  'display-28': '1.75rem',
  'display-32': '2rem',
  'display-40': '2.5rem',
  'display-48': '3rem',
  'display-52': '3.25rem',
  'display-56': '3.5rem',
  'display-64': '4rem',
  'display-72': '4.5rem',
  'display-80': '5rem',
  'display-92': '5.75rem',
  'display-96': '6rem'
} satisfies ThemeConfig['fontSize']

export { fontSize }
