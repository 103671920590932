import { tv as tvBase, type TV, type VariantProps } from 'tailwind-variants'
import * as theme from '@/theme'

const tv: TV = (options, config) => {
  return tvBase(options, {
    ...config,
    twMerge: config?.twMerge ?? true,
    twMergeConfig: {
      ...config?.twMergeConfig,
      theme: {
        ...config?.twMergeConfig?.theme
      },
      classGroups: {
        ...config?.twMergeConfig?.classGroups,
        ['boxShadow']: [
          {
            shadow: Object.keys(theme.boxShadow)
          }
        ],
        ['font-size']: [
          {
            text: Object.keys(theme.fontSize)
          }
        ]
      }
    }
  })
}

export { tv, VariantProps }
