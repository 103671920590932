import { ThemeConfig } from 'tailwindcss/types/config'

const borderRadius = {
  ['none']: '0px',
  ['xs']: '0.125rem',
  ['sm']: '0.25rem',
  ['md']: '0.375rem',
  ['lg']: '0.5rem',
  ['xl']: '0.75rem',
  ['2xl']: '1rem',
  ['3xl']: '1.25rem',
  ['4xl']: '1.5rem',
  ['full']: '50vh'
} satisfies ThemeConfig['borderRadius']

export { borderRadius }
