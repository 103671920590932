'use client'

import { useButtonGroup, useButtonGroupProps } from './use-button-group'
import { Text } from '@/components/ui/text'
import Button from './button'

export type ButtonGroupProps = useButtonGroupProps

const ButtonGroup = (props: ButtonGroupProps) => {
  const {
    className,
    styles,
    sectionName,
    shouldHideUrl,
    theme,
    title,
    buttons = [],
    componentProps
  } = useButtonGroup(props)

  if (buttons.length === 0) {
    return null
  }

  return (
    <div className={styles.base({ className })} {...componentProps}>
      {!!title && (
        <Text
          className={styles.title()}
          theme={theme}
          weight="semibold"
          size="tag"
          uppercase
        >
          {title}
        </Text>
      )}
      <div role="group" className={styles.buttons()}>
        {buttons.map((button) => (
          <Button
            key={`button-${button.id}`}
            sectionName={`${sectionName}_${button.text}`}
            href={button.url}
            newTab={button.newTab}
            title={button.text}
            subText={button.subText}
            appearance={button.appearance}
            color={button.color}
            size={button.size}
            image={button.image}
            leftIcon={button.leftIcon}
            rightIcon={button.rightIcon}
            fluid={button.fluid}
            fluidOnMobile={button.fluidOnMobile}
            hide={shouldHideUrl(button.url)}
          >
            {button.text}
          </Button>
        ))}
      </div>
    </div>
  )
}

export default ButtonGroup
