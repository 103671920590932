'use client'

import { UTM_FALLBACK, UTM_KEYS } from '@/config/utm'
import { QueryParams } from '@/contexts/TrackingProvider'

// @ts-ignore
export const getURLWithParams = (url, utm) => {
  const params = Object.entries({ ...url.params, ...utm })
    .filter(([key, value]) => value !== undefined && value !== null)
    .map(([key, value]) => (value === '' ? key : `${key}=${value}`))
    .join('&')

  return `${url.path}${params ? `?${params}` : ''}`
}

const getUTMParams = (
  url: string,
  params: QueryParams = {},
  content: string,
  referrer?: string
) => {
  if (!UTM_KEYS.some((param) => params && param in params)) return

  return {
    utm_source: params?.utm_source || referrer || UTM_FALLBACK.source,
    utm_medium: getUTMMediumParam(params, referrer),
    utm_campaign: params?.utm_campaign,
    utm_content: params?.utm_content,
    utm_term: params?.utm_term,
    click_section: content,
    ct: getCTParam(url, params)
  }
}

export const getCTParam = (url: string, params: QueryParams = {}) => {
  if (url?.includes('apps.apple.com')) {
    return params?.utm_campaign || UTM_FALLBACK.ct
  }

  return
}

export const getUTMMediumParam = (params: QueryParams, referrer?: string) => {
  if (params && params.utm_medium) {
    return params.utm_medium
  }

  if (referrer && referrer.includes('google')) {
    return UTM_FALLBACK.medium_google
  }

  return
}

const extractURLParams = (url: string) => {
  const hasQueryParams = url?.includes('?')
  const urlObject = url?.startsWith('http') ? new URL(url) : null

  const paramsString = hasQueryParams
    ? urlObject
      ? urlObject.search
      : url.substring(url.indexOf('?'))
    : ''

  const path = hasQueryParams
    ? urlObject
      ? urlObject.origin + urlObject.pathname
      : url.substring(0, url.indexOf('?'))
    : url

  const params = new URLSearchParams(paramsString)
  const paramsObject = Object.fromEntries(params.entries())

  for (const key in paramsObject) {
    if (key.startsWith('utm_') || key === 'referrer' || key === 'ct') {
      delete paramsObject[key]
    }
  }

  return {
    path,
    params: paramsObject
  }
}

export { getUTMParams, extractURLParams }
