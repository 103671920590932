'use client'

import NextLink from 'next/link'
import NextImage from 'next/image'
import { Icon } from '@/components/ui/icon'
import { Spinner } from '@/components/ui/spinner'
import { useButton, useButtonProps } from './use-button'

export type ButtonProps = useButtonProps

const Button = (props: ButtonProps) => {
  const {
    className,
    image,
    children,
    subText,
    styles,
    leftIcon,
    rightIcon,
    loadingLabel,
    hide,
    newTab,
    componentProps,
    isLoading,
    isDisabled,
    isWebsiteLink,
    getHrefWithUTM
  } = useButton(props)

  const renderChildren = () => {
    if (!!image) {
      return (
        <NextImage
          className={styles.image()}
          src={image.url}
          alt={image.alternativeText}
          width={image.width}
          height={image.height}
        />
      )
    }

    return (
      <>
        {isLoading && (
          <Spinner className={styles.icon()} label={loadingLabel} />
        )}
        <>
          {!!leftIcon && !isLoading && (
            <Icon
              name={typeof leftIcon === 'string' ? leftIcon : leftIcon?.name}
              className={styles.icon({
                className:
                  typeof leftIcon === 'string' ? '' : leftIcon?.className
              })}
            />
          )}
          {!subText ? (
            children
          ) : (
            <span className={styles.subTextWrapper()}>
              <span className={styles.subText()}>{subText}</span>
              {children}
            </span>
          )}
          {!!rightIcon && !isLoading && (
            <Icon
              name={typeof rightIcon === 'string' ? rightIcon : rightIcon?.name}
              className={styles.icon({
                className:
                  typeof rightIcon === 'string' ? '' : rightIcon?.className
              })}
            />
          )}
        </>
      </>
    )
  }

  if (hide) return null

  if (!props.href) {
    return (
      <button
        className={styles.base({ className })}
        {...componentProps}
        disabled={isDisabled}
        title={componentProps.title as string | undefined}
      >
        {renderChildren()}
      </button>
    )
  }

  return (
    <NextLink
      {...componentProps}
      className={styles.base({ className })}
      href={getHrefWithUTM()}
      target={newTab ? '_blank' : undefined}
      rel={isWebsiteLink ? undefined : 'noreferrer'}
      prefetch={isWebsiteLink ? true : false}
      title={componentProps.title as string | undefined}
    >
      {renderChildren()}
    </NextLink>
  )
}

export default Button
