'use client'

import { useCallback, useContext } from 'react'
import { TrackingContext } from '@/contexts/TrackingProvider'
import { UTM_FALLBACK } from '@/config/utm'
import { isEmpty } from '@/utils/object'
import {
  extractURLParams,
  getCTParam,
  getURLWithParams,
  getUTMParams
} from '@/utils/utm'

const useUTMParams = () => {
  const { tracking, referrer, queryParams } = useContext(TrackingContext)

  const appendUTMParams = useCallback(
    (url: string, content: string = '') => {
      const currentUrl = extractURLParams(url)

      const utmParamsFromPage = getUTMParams(
        url,
        queryParams,
        content,
        referrer
      )

      if (!isEmpty(utmParamsFromPage)) {
        return getURLWithParams(currentUrl, utmParamsFromPage)
      }

      const utmFromTracking = getUTMParams(url, tracking, content, referrer)

      if (!isEmpty(utmFromTracking)) {
        return getURLWithParams(currentUrl, utmFromTracking)
      }

      return getURLWithParams(currentUrl, {
        utm_source: referrer || UTM_FALLBACK.source,
        click_section: content,
        ct: getCTParam(url)
      })
    },
    [queryParams, referrer, tracking]
  )

  return {
    appendUTMParams
  }
}

export default useUTMParams
