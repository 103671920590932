import { SvgName, svg } from '@/svg'

export type IconProps = {
  className?: string
  name: SvgName
}

const Icon = ({ className = '', name }: IconProps) => {
  const Component = svg[name]

  if (!Component) return null

  return <Component className={`${className} shrink-0`} aria-hidden="true" />
}

export default Icon
