export const UTM_FALLBACK = {
  source: 'direct',
  ct: 'moises_website',
  medium_google: 'organic'
}

export const UTM_KEYS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content'
]
