const text = {
  white: `text-white hover:text-gray-20`,
  whiteAcqua: `text-white hover:text-acqua-500`,
  black: `text-black hover:text-gray-80`,
  gray: `text-gray-50 hover:text-white`,
  grayDark: `text-gray-800 hover:text-gray-300`,
  acqua: `text-acqua-500 hover:text-acqua-700`,
  acquaDark: `text-acqua-900 hover:text-acqua-700`,
  turquoise: `text-turquoise-500 hover:text-turquoise-700`,
  mint: `text-mint-500 hover:text-mint-700`
}

const solid = {
  white: `border-white bg-white text-black hover:border-gray-20 hover:bg-gray-20`,
  whiteAcqua: `border-white bg-white text-black hover:border-acqua-500 hover:bg-acqua-500`,
  black: `border-black bg-black text-white hover:border-gray-400 hover:bg-gray-400`,
  gray: `border-gray-300 bg-gray-300 text-white hover:border-gray-500 hover:bg-gray-500`,
  grayDark: `border-gray-800 bg-gray-800 text-white hover:border-gray-300 hover:bg-gray-300`,
  acqua: `border-acqua-500 bg-acqua-500 text-black hover:border-acqua-700 hover:bg-acqua-700`,
  acquaDark: `border-acqua-900 bg-acqua-900 text-white hover:border-acqua-700 hover:bg-acqua-700`,
  turquoise: `border-turquoise-500 bg-turquoise-500 text-black hover:border-turquoise-700 hover:bg-turquoise-700`,
  mint: `border-mint-500 bg-mint-500 text-black hover:border-mint-700 hover:bg-mint-700`
}

const outline = {
  white: `border-white text-white hover:bg-white hover:text-black`,
  whiteAcqua: `border-white text-white hover:bg-acqua-500 hover:border-acqua-500 hover:text-black`,
  black: `border-black text-black hover:bg-black hover:text-white`,
  gray: `border-gray-80 text-gray-50 hover:bg-gray-300 hover:border-gray-300 hover:text-white`,
  grayDark: `border-gray-800 text-gray-800 hover:bg-gray-800 hover:text-white`,
  acqua: `border-acqua-500 text-acqua-500 hover:bg-acqua-500 hover:text-black`,
  acquaDark: `border-acqua-900 text-acqua-900 hover:bg-acqua-900 hover:text-white`,
  turquoise: `border-turquoise-500 text-turquoise-500 hover:bg-turquoise-500 hover:text-black`,
  mint: `border-mint-500 text-mint-500 hover:bg-mint-500 hover:text-black`
}

const ghost = {
  white: `text-white hover:text-black`,
  whiteAcqua: `text-white hover:text-acqua-500`,
  black: `text-black hover:text-white`,
  gray: `text-gray-50 hover:text-white`,
  grayDark: `text-gray-800 hover:text-white`,
  acqua: `text-acqua-500 hover:text-acqua-700`,
  acquaDark: `text-acqua-900 hover:text-acqua-700`,
  turquoise: `text-turquoise-500 hover:text-turquoise-700`,
  mint: `text-mint-500 hover:text-mint-700`
}

const focus = {
  white: `focus:outline-acqua-500`,
  whiteAcqua: `focus:outline-acqua-500`,
  black: `focus:outline-acqua-500`,
  gray: `focus:outline-acqua-500`,
  grayDark: `focus:outline-acqua-500`,
  acqua: `focus:outline-acqua-500`,
  acquaDark: `focus:outline-acqua-900`,
  turquoise: `focus:outline-turquoise-500`,
  mint: `focus:outline-mint-500`
}

export { text, solid, outline, ghost, focus }
