'use client'

import { useEffect, useState } from 'react'

const useMobileOS = () => {
  const [mobileOS, setMobileOS] = useState<string | false>(false)

  useEffect(() => {
    const os = detectMobileOS()
    setMobileOS(os)
  }, [])

  return {
    mobileOS
  }
}

const detectMobileOS = () => {
  const userAgent = navigator.userAgent

  if (/windows phone/i.test(userAgent)) {
    return 'windows'
  } else if (/android/i.test(userAgent)) {
    return 'android'
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any)?.MSStream) {
    return 'ios'
  } else {
    return ''
  }
}

export default useMobileOS
