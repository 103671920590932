const colors = {
  transparent: 'transparent',
  white: '#FFFFFf',
  black: '#000000',
  gray: {
    5: '#F5F5F5',
    10: '#e8e8e8',
    20: '#d1d1d1',
    30: '#bfbfbf',
    40: '#a8a8a8',
    50: '#949494',
    60: '#858585',
    70: '#737373',
    80: '#666666',
    90: '#545454',
    100: '#474747',
    200: '#3b3b3b',
    300: '#333333',
    400: '#2d2d2d',
    500: '#262626',
    600: '#212121',
    700: '#1d1d1d',
    800: '#171717',
    900: '#111111'
  },
  acqua: {
    100: '#98fcff',
    300: '#41efff',
    500: '#00dae8',
    700: '#00b4cc',
    900: '#0086a0'
  },
  turquoise: {
    100: '#75ffe0',
    300: '#42ffd4',
    500: '#31eac0',
    700: '#0bd4a7',
    900: '#00ad86'
  },
  mint: {
    100: '#beffd4',
    300: '#89ffb1',
    500: '#63fb97',
    700: '#4cdd7e',
    900: '#33b25e'
  },
  yellow: {
    50: '#fefce8',
    100: '#fef9c3',
    200: '#fef08a',
    300: '#fde047',
    400: '#facc15',
    500: '#eab308',
    600: '#ca8a04',
    700: '#a16207',
    800: '#854d0e',
    900: '#713f12',
    950: '#422006'
  }
}

export { colors }
