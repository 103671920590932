import { useMemo } from 'react'
import { TextVariantProps, text } from './text.variants'

export type useTextProps = TextVariantProps & {
  tag?:
    | 'p'
    | 'span'
    | 'small'
    | 'strong'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'time'
    | 'cite'
    | 'q'
    | 'em'
    | 'label'
  className?: string
  id?: string
  children?: React.ReactNode
  style?: Record<string, string | number>
  dangerouslySetInnerHTML?: {
    __html: string
  }
  color?: string
}

export const useText = (props: useTextProps) => {
  const {
    tag = 'p',
    className = '',
    children,
    theme,
    size: sizeProp,
    weight,
    uppercase = false,
    italic = false,
    color = '',
    ...componentProps
  } = props

  const isTitle = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
  const size = (
    isTitle.includes(tag) ? sizeProp || tag : sizeProp
  ) as TextVariantProps['size']

  const styles = useMemo(
    () =>
      text({
        theme,
        size,
        weight,
        uppercase,
        italic
      }),
    [theme, size, weight, uppercase, italic]
  )

  return {
    Component: tag,
    className,
    children,
    theme,
    color,
    styles,
    componentProps
  }
}
