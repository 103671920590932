import { tv, VariantProps } from '@/utils/tv'

export const text = tv({
  slots: {
    base: []
  },
  variants: {
    theme: {
      dark: {
        base: 'text-white'
      },
      light: {
        base: 'text-black'
      }
    },
    size: {
      tiny: {
        base: 'text-display-12 tracking-widest'
      },
      tinyLabel: {
        base: 'text-display-14 tracking-wider'
      },
      label: {
        base: 'text-display-14 md:text-display-16 leading-tight'
      },
      body: {
        base: 'text-display-16 leading-tight'
      },
      tinyBody: {
        base: 'text-display-14'
      },
      subtitle: {
        base: 'text-display-18 lg:text-display-20 leading-relaxed'
      },
      description: {
        base: 'text-display-16 lg:text-display-18 leading-relaxed'
      },
      h1: {
        base: 'text-display-40 lg:text-display-48 xl:text-display-64 leading-none'
      },
      h2: {
        base: 'text-display-40 lg:text-display-48 xl:text-display-56 leading-tight'
      },
      h3: {
        base: 'text-display-32 lg:text-display-40 xl:text-display-48 leading-tight'
      },
      h4: {
        base: 'text-display-28 lg:text-display-32 xl:text-display-40 leading-tight'
      },
      h5: {
        base: 'text-display-24 lg:text-display-28 xl:text-display-32 leading-tight'
      },
      h6: {
        base: 'text-display-20 lg:text-display-24 leading-snug'
      },
      tag: {
        base: 'text-display-16 tracking-wide'
      },
      cigarbar: {
        base: 'text-display-14 xs:text-display-16 leading-snug'
      }
    },
    uppercase: {
      true: 'uppercase'
    },
    italic: {
      true: 'italic'
    },
    weight: {
      light: 'font-light',
      normal: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold'
    }
  },
  defaultVariants: {
    uppercase: false,
    italic: false
  }
})

export type TextVariantProps = VariantProps<typeof text>
