import { tv, VariantProps } from '@/utils/tv'

export const buttonGroup = tv({
  slots: {
    base: ['flex flex-col gap-4 sm:justify-start'],
    title: [],
    buttons: ['flex flex-row flex-wrap']
  },
  variants: {
    spacing: {
      xs: {
        buttons: 'gap-1'
      },
      sm: {
        buttons: 'gap-2'
      },
      md: {
        buttons: 'gap-3'
      },
      lg: {
        buttons: 'gap-4'
      }
    },
    theme: {
      dark: {
        title: 'text-gray-40'
      },
      light: {
        title: 'text-gray-60'
      }
    },
    align: {
      left: {
        title: 'text-left',
        buttons: 'justify-start rtl:ml-auto'
      },
      center: {
        title: 'text-center',
        buttons: 'justify-center'
      },
      center_left: {
        title: 'text-center md:text-left justify-center md:justify-start',
        buttons: 'justify-center md:justify-start'
      }
    },
    hideOnMobile: {
      false: {
        base: 'flex'
      },
      true: {
        base: 'hidden md:flex'
      }
    },
    show: {
      true: {},
      false: {
        buttons: 'invisible pointer-events-none'
      }
    }
  },
  defaultVariants: {
    theme: 'dark',
    align: 'left',
    spacing: 'md',
    hideOnMobile: false,
    show: false
  }
})

export type ButtonGroupVariantProps = Omit<
  VariantProps<typeof buttonGroup>,
  'show'
>
