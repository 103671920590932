'use client'

import { useMemo, useCallback } from 'react'
import { SvgName } from '@/svg'
import { dispatchGoogleEvent } from '@/utils/google'
import { EventAction } from '@/config/events'
import { Media } from '@/types'
import { ButtonVariantProps, button } from './button.variants'
import { slugify } from '@/utils/text'
import useUTMParams from '@/hooks/use-utm-params'

export type useButtonProps = ButtonVariantProps & {
  className?: string
  sectionName?: string
  href?: any
  type?: 'button' | 'submit'
  title?: string | null
  children?: React.ReactNode
  subText?: string | null
  image?: Media | null
  loadingLabel?: string
  eventAction?: EventAction
  leftIcon?: SvgName | { name: SvgName; className?: string } | null
  rightIcon?: SvgName | { name: SvgName; className?: string } | null
  isLoading?: boolean
  disabled?: boolean
  hide?: boolean
  newTab?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  tabIndex?: number
}

const regex = /^(https:\/\/(studio\.)?moises\.ai(\/[^\s]*)?)|(^\/[^\s]*)$/

export const useButton = (props: useButtonProps) => {
  const {
    className = '',
    sectionName = '',
    href,
    children,
    subText,
    image,
    eventAction = EventAction.WEBSITE_CLICK,
    leftIcon,
    rightIcon,
    appearance = 'text',
    size = 'md',
    color = 'white',
    weight = 'medium',
    disabled = false,
    rounded = false,
    fluid = false,
    fluidOnMobile = false,
    isLoading = false,
    wrap = false,
    loadingLabel,
    onClick,
    hide = false,
    newTab = false,
    ...componentProps
  } = props

  const isImage = !!image
  const isIconOnly = !children && (!!leftIcon || !!rightIcon)
  const isDisabled = isLoading || disabled
  const isWebsiteLink = regex.test(href)

  const utm = useUTMParams()

  const styles = useMemo(
    () =>
      button({
        appearance,
        size,
        color,
        weight,
        rounded,
        fluid,
        fluidOnMobile,
        isIconOnly,
        isImage,
        wrap
      }),
    [
      appearance,
      size,
      color,
      weight,
      rounded,
      fluid,
      fluidOnMobile,
      isIconOnly,
      isImage,
      wrap
    ]
  )

  const handleClick = useCallback(
    (e: React.MouseEvent<any>) => {
      if (isDisabled) return

      const { eventAction, eventLabel } = e.currentTarget.dataset

      if (eventAction && eventLabel) {
        dispatchGoogleEvent(eventAction, eventLabel)
      }

      if (!!onClick) {
        onClick(e)
      }
    },
    [isDisabled, onClick]
  )

  const slugifiedSectionName = slugify(sectionName)

  const getHrefWithUTM = () => {
    if (!href) {
      return ''
    }

    return utm.appendUTMParams(href, slugifiedSectionName)
  }

  return {
    image,
    children,
    className,
    subText,
    leftIcon,
    rightIcon,
    loadingLabel,
    getHrefWithUTM,
    newTab,
    styles,
    componentProps: {
      ...componentProps,
      onClick: handleClick,
      ['data-event-action']: eventAction,
      ['data-event-label']: slugifiedSectionName
    },
    hide,
    isLoading,
    isDisabled,
    isWebsiteLink
  }
}
