import { ThemeConfig } from 'tailwindcss/types/config'

const lineHeight = {
  none: '1',
  tight: '1.1',
  snug: '1.2',
  normal: '1.3',
  relaxed: '1.4',
  loose: '1.5'
} satisfies ThemeConfig['lineHeight']

export { lineHeight }
